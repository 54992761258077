<template>
<div class="contact row justify-content-center">
  <div class="container col-8">
    <h2>Contact</h2>

    <div class="content col-12 col-md-8">
      If you have any questions, please fill out the form
      We will get back to you as soon as possible

      <br>
      <br>
      Email: <a href="mailto:LauralMansfie@mail.com?subject=Question">LauralMansfie@mail.com</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped lang="scss">
.contact {
  position: static;
  background-color: #F2F0F0;
  height: 300px;
}
</style>
