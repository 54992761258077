<template>
  <div class="about row justify-content-center">
    <img src="../../public/images/bg-1.png" alt="" style="visibility: hidden">

    <div class="container col-8">
      <h2>About</h2>

      <div class="content">
        To be an excellent ninja, you must have calm judgment and keen observation.
        <br>
        Ninja Exam will test whether you have enough qualifications to become an excellent ninja.
        <br>
        Ninjas, join the challenge!
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped lang="scss">
.about {
  position: relative;
  background-image: url("../../public/images/bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;

  img {
    width: 100%;
  }
}
</style>
