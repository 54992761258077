<template>
  <div class="banner row">
    <img src="../../public/images/banner.png" alt="banner" style="visibility: hidden">
  </div>
</template>

<script>
export default {
  name: "Banner"
}
</script>

<style scoped lang="scss">
.banner {
  background-image: url("../../public/images/banner.png");
  background-repeat: no-repeat;
  background-size: contain;

  img {
    width: 100%;
  }
}
</style>
