<template>
  <footer class="footer row justify-content-center">
    <div class="col-12 col-md-5">
      © 2020 Ninja Exam. All Rights Reserved
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
  position: static;
  background-color: #000;
  height: 40px;
  color: #FFF;
  margin: 0 auto;

  span {
    text-align: center;
    line-height: 23px;
  }
}
</style>
