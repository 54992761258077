<template>
  <div class="how-to-play row justify-content-center">
    <img src="../../public/images/bg-2.png" alt="" style="visibility: hidden">

    <div class="container col-8">
      <h2>How To Play</h2>

      <div class="content">
        <ul>
          <li>Find squares of different colors within time.</li>
          <li>As the game level increases, the game difficulty will increase accordingly.</li>
        </ul>

        <div class="download row">
          <div class="col-12 col-md-6">
            <img src="../../public/images/iphone.png" alt="" style="margin-bottom: 20px;">
          </div>

          <div class="col-12 col-md-6">
            <h2>Features</h2>

            <div class="content">
              <span>
                The rules of the game are simple and easy to play.
                The fast and exciting game rhythm challenge your limit
              </span>
            </div>

            <div class="icon">
              <img src="../../public/images/icon.png" alt="">
            </div>

            <div class="store">
              <a class="link" href="https://apps.apple.com/us/app/ninja-exam/id1588226560" target="_blank">
                <img src="../../public/images/app-store.png" alt="">
              </a>
<!--              <a class="link" href="">-->
<!--                <img src="../../public/images/google-play.png" alt="">-->
<!--              </a>-->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HowToPlay"
}
</script>

<style scoped lang="scss">
.how-to-play {
  position: relative;
  background-image: url("../../public/images/bg-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 900px;

  @media (max-width: 768px) {
    min-height: 1200px;
  }

  img {
    width: 100%;
    max-width: 300px;
  }

  .download {
    margin-top: 50px;

    > img {
      width: 100%;
      min-width: 250px;
    }

    .icon {
      margin: 0 auto;

      > img {
        max-width: 150px;
      }
    }

    .store {
      .link {
        margin-top: 50px;
        height: 100%;

        > img {
          width: 100%;
          margin-top: 5%;
          max-width: 300px;
        }
      }
    }
  }
}
</style>
