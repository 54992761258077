<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link" @click="goAnchor('#about')">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="goAnchor('#howToPlay')">How To Play</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="goAnchor('#howToPlay')">Download</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="goAnchor('#contact')">Contact</a>
          </li>
        </ul>
      </div>
    </nav>

    <main>
      <Banner />
      <About id="about" />
      <HowToPlay id="howToPlay" />
      <Contact id="contact" />
    </main>

    <Footer />
  </div>
</template>

<script>
import Banner from "@/components/Banner"
import About from "@/components/About"
import HowToPlay from "@/components/HowToPlay"
import Contact from "@/components/Contact"
import Footer from "@/components/Footer"

export default {
  name: "App",
  components: {
    Banner,
    About,
    HowToPlay,
    Contact,
    Footer
  },
  methods: {
    goAnchor(Selector) {
      document.querySelector(Selector).scrollIntoView({
        behavior: "smooth"
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";

.navbar {
  opacity: 0.7;
}

.nav-item {

}
</style>
